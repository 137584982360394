<template>
  <fade-transition :duration="{enter: 300, leave: 300}" mode="out-in">

    <!-- Loading screen -->
    <div v-if="isLoading" key="animation">
      <LoadingScreen />
    </div>
    <!-- ExpertModal -->
    <!-- Experts list -->
    <div
      v-if="this.$route.params.therapyarea_url == 'oncology'"
      class="-mx-1 md:-mx-2 lg:-mx-4 flex flex-wrap items-stretch mt-9"
      key="content"
    >
      <p class="pb-6 text-xl text-left px-1 md:px-2 lg:px-4">
        Научная редакция по онкологии формирует научную образовательную программу по онкологии,
        соответствующей требованиям методических рекомендаций по созданию и системе оценки качества 
        образовательных модулей в рамках непрерывного медицинского образования; рецензирует существующие
        и новые образовательные видеоролики; отбирает клинические случаи для обсуждения на платформе.
      </p>
        <RedcollegeCard
          v-for="expert in sorted"
          :key="expert.id"
          :title="expert.name"
          :degree="expert.profession"
          :shortDescription="expert.shortDescription"
          :img="expert.imageUrl"
        />
    </div>
  </fade-transition>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

import { FadeTransition } from 'vue2-transitions'
import LoadingScreen from '@/components/LoadingScreen80vh.vue'
import RedcollegeCard from '@/components/RedcollegeCard.vue'

export default {
  name: 'Redcollege',
  components: {
    RedcollegeCard,
    LoadingScreen,
    FadeTransition,
  },
  data: function() {
    return {
      experts:[
        {
          name:"Федянин Михаил Юрьевич",
          profession:"доктор медицинских наук",
          shortDescription:"Старший научный сотрудник  ФГБУ «НМИЦ онкологии им. Н.Н. Блохина» МЗ РФ",
          imageUrl:"fedyanin.jpg"
        },
        {
          name:"Мамедли Заман Заурович",
          profession:"кандидат медицинских наук",
          shortDescription:"Заведующий проктологического отделения ФГБУ «НМИЦ онкологии им. Н.Н. Блохина» МЗ РФ",
          imageUrl:"mamedly.jpg"
        },
        {
          name:"Трофимова Оксана Петровна",
          profession:"доктор медицинских наук, профессор",
          shortDescription:"Ведущий научный сотрудник радиологического отделения ФГБУ «НМИЦ онкологии им. Н.Н. Блохина» МЗ РФ, профессор кафедры онкологии ФГБОУ ДПО 'Российская медицинская академия непрерывного медицинского образования'",
          imageUrl:"trofimova.jpg"
        },
        {
          name:"Хохлова Светлана Викторовна",
          profession:"доктор медицинских наук",
          shortDescription:"Заведующая отделением противоопухолевой лекарственной терапии ФГБУ «НМИЦ АГ и П им.В.И.Кулакова» МЗ РФ",
          imageUrl:"hohlova.jpg"
        },
        {
          name:"Феденко Александр Александрович",
          profession:"доктор медицинских наук",
          shortDescription:"Руководитель отдела лекарственного лечения опухолей МНИОИ им. П.А. Герцена – филиала ФГБУ «НМИЦ радиологии» МЗ РФ",
          imageUrl:"fedenko.jpg"
        },
        {
          name:"Трякин Алексей Александрович",
          profession:"доктор медицинских наук",
          shortDescription:"Главный научный сотрудник «НМИЦ онкологии им. Н.Н. Блохина» МЗ РФ, заведующий дневным стационаром ГБУЗ «МКНЦ им.А.С.Логинова», заместитель генерального директора по лекарственной терапии «Клиника К31 Сити»",
          imageUrl:"tryakin.jpg"
        }
      ],
      isLoading: false,
    }
  },
  computed:{
    ...mapState({
      /*experts: state => state.experts.experts,*/
      expertsArea: state => state.experts.area
    }),
    sorted(){
      return this.experts.sort(function(a,b){
        return a.name.localeCompare(b.name);
      });
    }
  }
}
</script>