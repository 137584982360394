<template>
  <fade-transition :duration="{enter: 300, leave: 300}" mode="out-in">

    <!-- Loading screen -->
    <div v-if="isLoading" key="animation">
      <LoadingScreen />
    </div>
    <!-- ExpertModal -->
    <!-- Experts list -->
    <div v-if="!isLoading" class="flex flex-wrap items-stretch mt-9" key="content">
      <ExpertCard
        v-for="expert in sorted"
        :key="expert.id"
        :title="expert.name"
        :degree="expert.profession"
        :shortDescription="expert.shortDescription"
        :description="expert.description"
        :img="expert.imageUrl"
        :videos="expert.videos"
      />
    </div>
  </fade-transition>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

import { FadeTransition } from 'vue2-transitions'
import LoadingScreen from '@/components/LoadingScreen80vh.vue'
import ExpertCard from '@/components/ExpertCard.vue'
import ExpertModal from '@/components/ExpertModal.vue'

export default {
  name: 'Experts',
  components: {
    ExpertCard,
    LoadingScreen,
    FadeTransition,
    ExpertModal
  },
  data: function() {
    return {
      isLoading: true,
      expertModalOptions: {
        component: require("@/components/ExpertModal.vue").default,
        backgroundColor: '#FFFFFF',
        opacity: '0.7'
      }
    }
  },
  computed:{
    ...mapState({
      experts: state => state.experts.experts,
      expertsArea: state => state.experts.area
    }),
    sorted(){
      return this.experts.sort(function(a,b){
        return a.name.localeCompare(b.name);
      });
    }
  },
  created: function() {
    if (this.experts && (this.expertsArea == this.$route.params.therapyarea_url)) {
      this.isLoading = false
    } else {
      this.$store.dispatch('experts/getTherapyAreaExperts', this.$route.params.therapyarea_url)
        .then(() => {
          this.isLoading = false
        })
    }
  }
}
</script>

<style>
</style>
