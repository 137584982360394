<template>
  <div
    class="flex px-1 md:px-2 lg:px-4 w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/4 pb-8"
  >
    <div class="bg-white rounded-lg shadow-md pb-4">
      <img 
        class="w-full h-auto rounded-t-lg"
        :src="require('@/assets/img/experts/' + img + '')"/>
      <h2 class="text-xl pt-3 px-4 font-semibold text-primary-500 mx-auto text-center">{{ title }}</h2>
      <h3 class="text-md pt-1 px-4 font-semibold text-secondary mx-auto text-center">{{ degree }}</h3>
      <p class="pt-1 px-4 font-medium text-left">{{ shortDescription }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RedcollegeCard',
  components: {},
  props: {
    title: String,
    degree: String,
    shortDescription: String,
    img: String,
  },
}
</script>

<style scoped>
</style>
