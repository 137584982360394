<template>
  <div class="flex px-2 md:px-4 py-8 sm:w-2/3 max-h-full">
    <div class="bg-white rounded-lg shadow-md relative overflow-y-auto scrollbar-style scrolling-touch">
      <img 
        class="md:w-1/2 h-auto rounded-lg md:float-left relative mr-6"
        :src="require('@/assets/img/experts/' + img + '')"/>
        <p 
        @click="closeClick()"
        class="absolute right-0 top-0 text-3xl text-right text-gray-600 hover:text-primary-500 pr-3 cursor-pointer"><font-awesome-icon icon="times"/></p>
        <h2 class="text-xl pt-10 px-4 font-semibold text-primary-500 mx-auto text-center">{{ title }}</h2>
        <h3 class="text-md pt-1 px-4 font-semibold text-secondary mx-auto text-center">{{ degree }}</h3>
        <p class="pt-1 px-4 font-medium text-left">{{ shortDescription }}</p>
        <h3 v-if="description" class="text-md pt-2 px-4 font-semibold text-secondary mx-auto text-left">Об эксперте:</h3>
        <p class="pt-1 px-4 font-medium text-left">{{ description }}</p>
        <h3 v-if="videos.length!=0" class="text-md pt-2 px-4 font-semibold text-secondary mx-auto text-left">Список видео:</h3>
        <ol class="list-disc text-left pl-8 pb-6">          
          <router-link
            @click.native="closeClick()"
            v-for="video in videos"
            :key="video.id"
            :to="'/' + areaUrl + '/videos/' + video.id"
            class="hover:underline hover:text-primary-500 cursor-pointer"
          >
            <li>{{ video.name }}</li>
          </router-link>
        </ol>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ExpertModal from '@/components/ExpertModal.vue'

export default {
  name: 'ExpertModal',
  props: {
    title: String,
    degree: String,
    shortDescription: String,
    description: String,
    img: String,
    videos: Array
  },
  computed: {
    ...mapState({
      'areaUrl': state => state.areaURL
    })
  },
  methods:{
    closeClick() {
      this.$xmodal.close()
    }
  }
}
</script>

<style>
.scrollbar-style::-webkit-scrollbar {
    width: 0.5rem;
}
 
/* Track */
.scrollbar-style::-webkit-scrollbar-track {
    background: #e2e8f0;
}
 
/* Handle */
.scrollbar-style::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: #718096;; 
}
</style>
